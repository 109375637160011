import React from 'react'
import cx from 'classnames'
import { prepareParagraph, sanityImageUrl, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ study }) => {
  return (
    <>
      <div className={styles.patientsAndMethods}>
        <div className={styles.anchor} id={titleToId(study.titleSectionNav)} data-js="scroll-section" />
        <h2 className={styles.title}>{study.titleSection}</h2>
      </div>

      <div className={styles.study}>
        <div className={styles.anchor} id={titleToId(study.title)} data-js="scroll-section" />
        <h2 className={styles.title}>{study.title}</h2>
        <div className={styles.content}>
          {study.Content &&
            study.Content.map(block => (
              <div className={styles.paragraph}>
                <p
                  className={cx(styles.headline, {
                    [styles.headlineFull]: block.isFullWidth,
                  })}
                >
                  {prepareParagraph(block.title)}
                </p>

                {block.body &&
                  block.body.map(item => (
                    <div className={styles.item}>
                      {item._type === 'info' && <p className={styles.info}>{prepareParagraph(item.info)}</p>}

                      {item._type === 'infoImage' && (
                        <div className={styles.infoImage}>
                          <p className={styles.info}>{prepareParagraph(item.info)}</p>
                          <div className={styles.image}>
                            <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                          </div>
                        </div>
                      )}

                      {item._type === 'imageFull' && (
                        <div className={styles.imageFull}>
                          <p className={styles.caption}>{prepareParagraph(item.title)}</p>
                          <img src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                        </div>
                      )}

                      {item._type === 'table1Column' && (
                        <>
                          <div className={styles.table1}>
                            <p className={styles.table1Title}>{item.title}</p>
                            <ul className={styles.table1List}>
                              {item.items &&
                                item.items.map((item, index) => (
                                  <li key={index} className={styles.table1Item}>
                                    {item}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <p className={styles.table1Description}>{item.description}</p>
                        </>
                      )}

                      {item._type === 'list' && (
                        <div className={styles.list}>
                          <ul className={styles.listItems}>
                            {item.items &&
                              item.items.map((item, index) => (
                                <li key={index} className={styles.listItem}>
                                  <img
                                    src={sanityImageUrl(item.icon)}
                                    className={styles.listImage}
                                    alt={item.icon?.caption}
                                  />
                                  <p className={styles.listText}>{item.text}</p>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}

                      {item._type === 'fullTable3' && (
                        <div className={styles.fullTable}>
                          <div className={styles.col}>
                            <p className={styles.fullTableTitle}>{prepareParagraph(item.title1)}</p>
                            <p className={styles.fullTableBody}>{prepareParagraph(item.body1)}</p>
                          </div>
                          <div className={styles.col}>
                            <p className={styles.fullTableTitle}>{prepareParagraph(item.title2)}</p>
                            <p className={styles.fullTableBody}>{prepareParagraph(item.body2)}</p>
                          </div>
                          <div className={styles.col}>
                            <p className={styles.fullTableTitle}>{prepareParagraph(item.title3)}</p>
                            <p className={styles.fullTableBody}>{prepareParagraph(item.body3)}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ limitations }) => {
  return (
    <div className={styles.limitations}>
      <div className={styles.anchor} id={titleToId(limitations.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{limitations.title}</h2>

      <div className={styles.content}>
        {limitations.Content &&
          limitations.Content.map(block => (
            <div className={styles.paragraph}>
              <p className={styles.headline}>{prepareParagraph(block.title)}</p>
            </div>
          ))}
      </div>
    </div>
  )
}

import React from 'react'
import { titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ hypotheses }) => {
  return (
    <div className={styles.hypotheses}>
      <div className={styles.anchor} id={titleToId(hypotheses.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{hypotheses.title}</h2>
      <div className={styles.content}>
        <p className={styles.description}>{hypotheses.warning}</p>
        <ul className={styles.list}>
          {hypotheses.items &&
            hypotheses.items.map(item => (
              <li key={item._key} className={styles.item}>
                <p className={styles.headline}>{item.title}</p>
                <p className={styles.text}>{item.text}</p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
